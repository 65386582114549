import classNames from "common/class-names";
import { AppArticleDetailsDto } from "dto/components/app-article-info/app-article-dto";

const AppArticleDetails = ({ content }: AppArticleDetailsDto) => {
	if (!content || content?.length === 0) {
		return null;
	}
	return (
		<div className="app-article-details">
			<div className="article-details">
				{content.map((item, index) => {
					if (!item.subTitle && !item.subContent) return null;

					const itemClassNames = classNames({
						"article-details__content": true,
						"article-details__content--first": index === 0 && content?.length > 0,
					});

					return (
						<div key={index} className={itemClassNames}>
							<p className="article-details__title">{item.subTitle}</p>
							<p className="article-details__description">{item.subContent}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AppArticleDetails;
