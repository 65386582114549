import { useEffect } from "react";

import { AppSlideShowProps } from "dto/components/app-slideshow-dto";

const AppSlideShow = ({ itemToRender, sliderBackgroundColor, gap, imgHeight, imgWidth }: AppSlideShowProps) => {
	const root = document.documentElement;
	const style = getComputedStyle(root);
	const marqueeContent = document.querySelector("ul.marquee-content");
	const marqueeElementsDisplayed = parseInt(style.getPropertyValue("--marquee-elements-displayed"), 10);
	const marqueeElements = root.style.getPropertyValue("--marquee-elements");
	const images = itemToRender || [];

	useEffect(() => {
		if (itemToRender?.length === 0 || !marqueeContent || marqueeContent?.children?.length === 0 || marqueeElements || marqueeElements?.trim()) {
			return;
		}

		for (let i = 0; i < marqueeElementsDisplayed; i++) {
			marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
		}

		root.style.setProperty("--marquee-elements", marqueeContent.children.length.toString());
	}, [marqueeContent, marqueeContent?.children, root, marqueeElementsDisplayed, marqueeElements, itemToRender?.length]);

	useEffect(() => {
		// Cleanup when when navigating away
		return () => {
			root.style.setProperty("--marquee-elements", "");
		};
	}, [root]);

	return (
		<div className="app-slideshow">
			<div className="slide-container">
				<div className="marquee-wrapper">
					<div className="marquee" style={{ backgroundColor: sliderBackgroundColor ?? "transparent" }}>
						<ul className="marquee-content" style={{ gap: gap ?? 0 }}>
							{images.map((item, index) => (
								<li key={index} className="marquee-content__item">
									<img style={{ width: imgWidth ?? "100%", height: imgHeight ?? "100%" }} src={item?.url ?? item?.logo} alt="" />
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppSlideShow;
