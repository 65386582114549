//dto
import AppOfficeCardDto from "dto/components/app-office-card/app-office-card-dto";

//assets
import emailIcon from "assets/images/components/app-office-card/email-icon.svg";

const AppOfficeCard = ({ title, location, address1, address2, address3, city, state, postcode, email, googleMapLink }: AppOfficeCardDto) => {
	return (
		<div className="app-office-card">
			<div className="office-card">
				<p className="office-card__title">{title}</p>

				<p className="office-card__location">{location}</p>

				{/*prettier-ignore*/}
				<div className="office-card__address">
					<p className="office-card__text">{address1}</p>

					{address2 && <p className="office-card__text">{address2}</p>}

					{address3 && <p className="office-card__text">{address3}</p>}

					<p className="office-card__text">{city}</p>

					{state && (<p className="office-card__text">{state} {postcode}</p>)}

				</div>

				<div className="office-card__email">
					<img src={emailIcon} alt="email" className="office-card__icon" />

					<a href={`mailto:${email}`}>{email}</a>
				</div>

				<a href={googleMapLink} target="_blank" rel="noopener noreferrer" className="office-card__button">
					Get Directions
				</a>
			</div>
		</div>
	);
};

export default AppOfficeCard;
