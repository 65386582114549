import AppWorkHereCardDto from "dto/components/app-work-here-card-dto";

const AppWorkHereCard = ({ title, description, image }: AppWorkHereCardDto) => {
	return (
		<div className="app-work-here-card">
			<div className="work-here-card">
				<div className="work-here-card__header">
					<div className="work-here-card__wrapper">
						<img className="work-here-card__icon" src={image} alt="icon" />
					</div>

					<p className="work-here-card__title">{title}</p>
				</div>

				<p className="work-here-card__description">{description}</p>
			</div>
		</div>
	);
};
export default AppWorkHereCard;
