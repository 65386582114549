import FooterLinkDto from "dto/components/app-footer/footer-link-dto";

const FooterLink = ({ url, iconUrl, label, isTnc }: FooterLinkDto) => {
	const onHandleClick = () => {
		if (url) window.open(url, "_blank");
	};

	if (isTnc) {
		return (
			<button className="footer-link__transparent-container" onClick={onHandleClick}>
				<p className="footer-link__text">{label}</p>
			</button>
		);
	}

	return (
		<button className="footer-link__container" onClick={onHandleClick}>
			<img className="footer-link__icon" src={iconUrl} alt={label} />
		</button>
	);
};

export default FooterLink;
