import { useState } from "react";

import plusIcon from "assets/images/pages/page-home/plus-icon.svg";
import minusIcon from "assets/images/pages/page-home/minus-icon.svg";

const AppFaqCard = ({ question, answer, index }: any) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	return (
		<div className="app-faq-card">
			<div className="faq-card">
				<div className="faq-card__number-box">
					<p className="faq-card__number-text">{(index + 1).toString().padStart(2, "0")}</p>
				</div>

				<div className="faq-card__wrapper">
					<div className="">
						<p className="faq-card__question">{question}</p>

						{isExpanded && <p className="faq-card__answer">{answer}</p>}
					</div>

					{/* prettier-ignore */}
					<img className="faq-card__icon" src={isExpanded ? minusIcon : plusIcon} alt={"expand icon"} onClick={() => {setIsExpanded(!isExpanded)}} />
				</div>
			</div>
		</div>
	);
};

export default AppFaqCard;
