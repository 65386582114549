import { AppPositionCardDto } from "dto/components/app-position-card-dto";

import AppButton from "components/app-button";

const AppPositionCard = ({ title, jobType, description, onHandleApplyNow }: AppPositionCardDto) => {
	return (
		<div className="app-position-card">
			<div className="position-card">
				<p className="position-card__title">{title}</p>

				<p className="position-card__description">
					Job Types: <span className="job-type">{jobType}</span>
				</p>

				<p className="position-card__description">{description}</p>

				<div className="position-card__section">
					<AppButton className="apply-button" label="Apply Now" onClick={() => onHandleApplyNow(title, jobType)} />
				</div>
			</div>
		</div>
	);
};

export default AppPositionCard;
