//library
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ArticleDetailsDto, ArticleDto } from "dto/components/app-article-info/app-article-dto";

// components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppButton from "components/app-button";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppArticleCard from "components/app-article-card";
import AppArticleInfo from "components/app-article-info";
import AppArticleDetails from "components/app-article-details";

const PageArticle = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [articleDetails, setArticleDetails] = useState<ArticleDetailsDto>();
	const [similarArticles, setSimilarArticles] = useState<ArticleDto[]>([]);

	const onHandleGetSimilarArticles = useCallback(async (category: string) => {
		try {
			const response = await api.get.similarArticle.article(category);
			setSimilarArticles(response.data.data.list.slice(0, 3));
		} catch (error) {
			serveRequestErrors(error as Error | ErrorResponseDto);
		}
	}, []);

	const onHandleGetArticleDetails = useCallback(async () => {
		if (!id) return;

		try {
			const response = await api.get.articleDetails.article(id);
			const data = response.data.data;

			setArticleDetails(data);
			onHandleGetSimilarArticles(data.category);
		} catch (error) {
			serveRequestErrors(error as Error | ErrorResponseDto);
		}
	}, [id, onHandleGetSimilarArticles]);

	useEffect(() => {
		onHandleGetArticleDetails();
	}, [onHandleGetArticleDetails]);

	return (
		<div className="page-article">
			<div className="article">
				<AppNavbar />

				<HeaderCard backgroundImage={articleDetails?.thumbnail} title={articleDetails?.title} customClass="align-end" />

				<div className="content">
					<div className="content__left">
						<AppArticleInfo publicationDate={articleDetails?.date} category={articleDetails?.category} tableOfContent={articleDetails?.tableOfContent || []} attachments={articleDetails?.attachments || []} />
					</div>

					<div className="content__right">
						<AppArticleDetails content={articleDetails?.content || []} />
					</div>
				</div>

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">Similar News</p>

						<AppButton label="View All Articles" onClick={() => navigate(pathnames.PageAllArticles)} tertiary />
					</div>

					<div className="articles-section__wrapper">
						{similarArticles.map((o, i) => (
							<AppArticleCard title={o.title} description={o.category} image={o.thumbnail} key={o.id} link={`${pathnames.PageArticle}/${o.id}`} />
						))}
					</div>
				</div>

				<AppNewsCard />
				<AppFooter />
			</div>
		</div>
	);
};

export default PageArticle;
