import { useNavigate } from "react-router-dom";

const AppArticleCard = ({ title, description, image, link }: any) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(link);
	};

	return (
		<div className="app-article-card">
			<div className="article-card">
				<img className="article-card__image" src={image} alt="articleImage" onClick={handleClick} />

				<p className="article-card__title">{title}</p>

				<p className="article-card__description">{description}</p>
			</div>
		</div>
	);
};
export default AppArticleCard;
