import FooterAddressDto from "dto/components/app-footer/footer-address-dto";

const FooterAddress = ({ title, description, children }: FooterAddressDto) => {
	return (
		<div className="footer-address">
			<p className="footer-address__title">{title}</p>

			{description && <p className="footer-address__description">{description}</p>}

			{children}
		</div>
	);
};

export default FooterAddress;
