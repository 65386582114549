// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// hooks
import useTemplateContext from "hooks/use-template-context";

// common
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ClientDto, ArticleDto, FaqDto, PartnerListDto, ServiceDto, ProsDto } from "dto/pages/page-home-dto";

// component
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppButton from "components/app-button";
import AppNewsCard from "components/app-news-card";
import AppSlideShow from "components/app-slideshow";
import AppPagination from "components/app-pagination";
import AppArticleCard from "components/app-article-card";
import AppFaqCard from "components/pages/page-home/app-faq-card";
import AppCompanyCard from "components/pages/page-home/app-company-card";
import AppServiceSolutionCard from "components/pages/page-home/app-service-solution-card";

const PageHome = () => {
	const navigate = useNavigate();
	const sliderRef = useRef<HTMLDivElement | null>(null);
	const queryParams = useRef({ search: "", page: 0, size: 9 });
	const [videoIndex, setVideoIndex] = useState<number>(0);
	const [videoKey, setVideoKey] = useState(Date.now());
	const [main1Services, setMain1Services] = useState<ServiceDto[]>([]);
	const [main2Services, setMain2Services] = useState<ServiceDto[]>([]);
	const [clientsData, setClientsData] = useState<ClientDto[]>([]);
	const [partnerList, setPartnerList] = useState<PartnerListDto[]>([]);
	const [faqData, setFaqData] = useState<FaqDto[]>([]);
	const [articleData, setArticleData] = useState<ArticleDto[]>([]);
	const [headerClassName, setHeaderClassName] = useState("app-navbar__fixed");
	const { getFilteredResources } = useTemplateContext();

	const homePage = useMemo(() => getFilteredResources("page.landing"), [getFilteredResources]);
	const getPageData = useCallback((key: string) => homePage?.find((value) => value.key === key)?.value?.toString() ?? "", [homePage]);
	const coverImage = useMemo(() => getPageData("page.landing.cover.image"), [getPageData]);
	const partnerHeader = useMemo(() => getPageData("page.landing.partner.header"), [getPageData]);
	const serviceHeader = useMemo(() => getPageData("page.landing.service.header"), [getPageData]);
	const serviceDescription = useMemo(() => getPageData("page.landing.service.description"), [getPageData]);
	const serviceSubHeader = useMemo(() => getPageData("page.landing.service.subHeader"), [getPageData]);
	const serviceSubHeaderPartner = useMemo(() => getPageData("page.landing.service.subHeaderPartner"), [getPageData]);
	const prosHeader = useMemo(() => getPageData("page.landing.pros.header"), [getPageData]);
	const prosDescription = useMemo(() => getPageData("page.landing.pros.description"), [getPageData]);
	const clientHeader = useMemo(() => getPageData("page.landing.client.header"), [getPageData]);
	const clientViewLabel = useMemo(() => getPageData("page.landing.client.view.label"), [getPageData]);
	const serviceViewLabel = useMemo(() => getPageData("page.landing.service.view.label"), [getPageData]);
	const articleHeader = useMemo(() => getPageData("page.landing.article.header"), [getPageData]);
	const articleViewLabel = useMemo(() => getPageData("page.landing.article.view.label"), [getPageData]);
	const faqHeader = useMemo(() => getPageData("page.landing.faq.header"), [getPageData]);
	const faqDescription = useMemo(() => getPageData("page.landing.faq.description"), [getPageData]);
	const coverTitle = useMemo(() => getPageData("page.landing.catch.phrase"), [getPageData]);
	const splitCoverTitle = useMemo(() => (Boolean(coverTitle) ? coverTitle.split(" <position> ") : []), [coverTitle]);
	const coverPosition = useMemo(() => (homePage?.find((value) => value.key === "page.landing.job.position")?.value as string[]) || [], [homePage]);
	const prosData = useMemo(() => (homePage?.find((value) => value.key === "page.landing.pros")?.value as ProsDto[]) || [], [homePage]);

	const onHandlePlayBackVideo = useCallback(() => {
		if (videoIndex !== 0) {
			setVideoIndex((prev) => --prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex]);

	const onHandlePlayNextVideo = useCallback(() => {
		if (videoIndex !== clientsData.length - 1) {
			setVideoIndex((prev) => ++prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex, clientsData.length]);

	const onHandleSwitchVideo = useCallback((index: number) => {
		setVideoIndex(index);

		setVideoKey(Date.now());
	}, []);

	const onHandleGetPartnerList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.partner.partner();

			setPartnerList(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetServices = useCallback(async () => {
		try {
			const params = queryParams.current;

			const payload = { size: 9, page: params.page };

			const [responseMain1, responseMain2] = await Promise.all([api.get.services.services("main1", payload), api.get.services.services("main2", payload)]);

			setMain1Services(responseMain1.data.data.list.content);

			setMain2Services(responseMain2.data.data.list.content);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const getYouTubeEmbedUrl = (url?: string) => {
		let videoId = undefined;

		if (!url) return undefined;

		if (url.includes("v=")) {
			videoId = url.split("v=")[1]?.split("&")[0];
		} else if (url.includes("youtu.be/")) {
			videoId = url.split("youtu.be/")[1];
		}

		if (!videoId) {
			return undefined;
		}

		return `https://www.youtube-nocookie.com/embed/${videoId}?disablekb=1&controls=0&showinfo=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&&autoplay=1&loop=1&mute=1&playlist=${videoId}`;
	};

	const onHandleGetClientData = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.clients.clients();

			const clientsList = response.data.data.list.map((client: ClientDto) => ({
				...client,
				mediaType: client.cover.mediaType || (client.cover ? "video" : "image"),
				mediaUrl: client.cover.coverPath || client.thumbnail,
			}));

			setClientsData(clientsList);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;
			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetFaq = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.faq.faq();

			setFaqData(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleOurArticles = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 3, page: params.page, param: params.search };

			response = await api.get.ourArticles.latestArticles(payload);

			setArticleData(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const navigateToProjectShowcase = (projectId: string) => {
		navigate(`${pathnames.PageProjectShowCase}/${projectId}`);
	};

	const navigateToServices = () => {
		navigate(pathnames.PageAllProducts);
	};
	const onHandleNext = useCallback(() => {
		scrollSliderBy("right");
		onHandlePlayNextVideo();
	}, [onHandlePlayNextVideo]);

	const onHandleBack = useCallback(() => {
		scrollSliderBy("left");
		onHandlePlayBackVideo();
	}, [onHandlePlayBackVideo]);

	const scrollSliderBy = (direction: "left" | "right") => {
		if (!sliderRef.current) return;
		const itemWidth = 340;

		if (direction === "right") {
			sliderRef.current.scrollBy({ left: itemWidth, behavior: "smooth" });
		} else {
			sliderRef.current.scrollBy({ left: -itemWidth, behavior: "smooth" });
		}
	};

	useEffect(() => {
		onHandleGetClientData();
		onHandleGetFaq();
		onHandleOurArticles();
		onHandleGetPartnerList();
		onHandleGetServices();
	}, [onHandleGetClientData, onHandleGetFaq, onHandleGetPartnerList, onHandleGetServices, onHandleOurArticles]);

	useEffect(() => {
		const isFixedHeader = () => {
			const scrolledTo = window.scrollY;

			if (scrolledTo >= 120) setHeaderClassName("app-navbar__fixed--active");
			else setHeaderClassName("app-navbar__fixed");
		};

		window.addEventListener("scroll", isFixedHeader);

		return () => window.removeEventListener("scroll", isFixedHeader);
	}, []);

	useEffect(() => {
		if (coverPosition.length > 0) {
			const items: string[] = coverPosition;
			const container = document.getElementById("banner-items");

			const onHandleSlideUp = (item: HTMLLIElement, i: number) => {
				const lastItem = i === items.length - 1;

				setTimeout(() => {
					if (container && container.lastChild) container?.removeChild(container.lastChild);
					container?.appendChild(item);
					item.classList.add("slide-up-wording");

					if (lastItem) {
						onHandleAnimate();
					}
				}, 3000 * i);
			};

			const onHandleAnimate = () => {
				items.forEach((o, i) => {
					const item = document.createElement("li");
					item.className = "list__item";
					item.innerHTML = o;
					onHandleSlideUp(item, i);
				});
			};

			onHandleAnimate();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="page-home">
			<div className="home">
				<AppNavbar className={headerClassName} />

				<div className="home-container">
					<div className="home__height">
						<div className="header" style={{ backgroundImage: `url(${coverImage})` }}></div>
					</div>

					<div className="home-wrapper">
						<div className="context">
							<div className="context__text">
								{splitCoverTitle.length > 0 && <span>{splitCoverTitle[0]}</span>}
								<span className="content__container">
									<ul className="list" id="banner-items" />
								</span>
								{splitCoverTitle?.map((item, index) => {
									if (index === 0) return null;

									/*prettier-ignore*/
									return (<span className="context__cover-text" key={index}>{item}</span>);
								})}
							</div>
						</div>
					</div>
				</div>

				<div className="partners-section">
					<p className="partners-section__title" id="animation-3">
						{partnerHeader}
					</p>

					{partnerList.length > 0 && <AppSlideShow itemToRender={partnerList} gap="20px" imgWidth="142px" />}
				</div>

				<div className="services-section">
					<p className="services-section__title">{serviceHeader}</p>

					<p className="services-section__description">{serviceDescription}</p>

					<p className="services-section__subtitle">{serviceSubHeader}</p>

					<div className="services-section__wrapper">
						{main1Services.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					<p className="services-section__subtitle">{serviceSubHeaderPartner}</p>

					<div className="services-section__wrapper">
						{main2Services.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					{serviceViewLabel && (
						<div className="services-section__button-container">
							<AppButton label={serviceViewLabel} onClick={navigateToServices} />
						</div>
					)}
				</div>

				<div className="company-intro">
					<p className="company-intro__title">{prosHeader}</p>

					<p className="company-intro__description">{prosDescription}</p>

					<div className="company-intro__wrapper">
						{prosData?.length > 0 && prosData.map((o, i) => <AppCompanyCard key={i} description={o.description} number={o.number} workforce={o.workforce} description1={o.description1} />)}
					</div>
				</div>

				{clientsData.length > 0 && (
					<div className="clients-section">
						<div className="clients-section__body">
							<div className="clients-section__wrapper">
								<div>
									<p className="clients-section__title">{clientHeader}</p>

									<div key={clientsData[videoIndex].id}>
										<p className="clients-section__sub-title">{clientsData[videoIndex].title}</p>

										<p className="clients-section__description">{clientsData[videoIndex].information}</p>

										{clientViewLabel && <AppButton label={clientViewLabel} onClick={() => navigateToProjectShowcase(clientsData[videoIndex].id)} secondary uppercase />}
									</div>
								</div>

								{/*prettier-ignore*/}
								<AppPagination totalPageNumber={clientsData.length} currentPage={videoIndex} onHandleNext={onHandleNext} onHandleBack={onHandleBack}/>
							</div>

							<div className="clients-section__container">
								<div className="clients-section__slider-container">
									<div className="slider" ref={sliderRef}>
										{clientsData.map((o, i) => {
											const imageClassName = classNames({ slider__image: true, "slider__image--active": i === videoIndex });
											return (
												<div className="slider__item" key={i} onClick={() => onHandleSwitchVideo(i)}>
													<div className={imageClassName} style={{ backgroundImage: `url(${o.thumbnail})` }} />

													<div className="slider__wrapper">
														<p className="slider__title">{o.title}</p>

														<p className="slider__description">{o.information}</p>

														<p className="slider__text">{o.platform}</p>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<div className="clients-section__youtube">
								{clientsData[videoIndex].cover.mediaType === "VIDEO" ? (
									<iframe
										key={videoKey}
										width="100%"
										height="720px"
										src={getYouTubeEmbedUrl(clientsData[videoIndex].cover.coverPath)}
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										title={`YouTube video ${videoIndex}`}
									/>
								) : (
									<img className="clients-section__image" src={clientsData[videoIndex].cover.coverPath} alt={clientsData[videoIndex].title} style={{ width: "100%", height: "auto" }} />
								)}
							</div>
						</div>
					</div>
				)}

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">{articleHeader}</p>

						{articleViewLabel && <AppButton label={articleViewLabel} onClick={() => navigate(pathnames.PageAllArticles)} tertiary />}
					</div>

					<div className="articles-section__wrapper">
						{articleData.map((o, i) => {
							const articleLink = `${pathnames.PageArticle}/${o.id}`;

							return <AppArticleCard title={o.title} description={o.category} image={o.thumbnail} key={i} link={articleLink} />;
						})}
					</div>
				</div>

				<div className="faq-section">
					<p className="faq-section__title">{faqHeader}</p>

					{faqDescription && <p className="faq-section__description">{faqDescription}</p>}

					<div className="faq-section__container">
						{faqData.map((o, i) => {
							return <AppFaqCard question={o.question} answer={o.answer} index={i} key={i} />;
						})}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageHome;
