import { useContext } from "react";

import { TemplateContext } from "contexts/with-template-context";

const useTemplateContext = () => {
	const context = useContext(TemplateContext);

	if (context === undefined) throw new Error("TemplateContext was used outside of the TemplateProvider");

	return context;
};

export default useTemplateContext;
