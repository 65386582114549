import axios from "axios";
import { AppDispatch, store } from "../store/store";
import sanitizeError from "common/sanitize-error";
import { promptAlertMessage } from "store/slices/alert-slice";
import ErrorResponseDto from "dto/services/error-response-dto";
import { ServeRequestErrorsCallbackDto } from "dto/common/serve-request-errors-dto";

const isAxiosCancelled = "isAxiosCancelled";

export const axiosCancelled = (error: ErrorResponseDto | Error | string) => axios.isCancel(error);

const serveRequestErrors = (error: ErrorResponseDto | Error | string, callback?: ServeRequestErrorsCallbackDto) => {
	const dispatch: AppDispatch = store.dispatch;
	const requestCancelled = axios.isCancel(error);

	if (requestCancelled) return isAxiosCancelled;

	const sanitizedError = sanitizeError(error);

	if (callback) {
		callback(sanitizedError);
	} else {
		dispatch(promptAlertMessage({ error: true, message: sanitizedError }));
	}

	return sanitizedError;
};

export default serveRequestErrors;
