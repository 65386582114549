import { memo } from "react";

const AppScreenLoader = () => {
	const stringToRender = "Loading";

	return (
		<div className="app-screen-loader">
			<div className="screen-loader">
				<div className="screen-loader__container">
					<p className="screen-loader__text" aria-label="Loading">
						{stringToRender?.split("")?.map((item, index) => (
							<span key={index} className="screen-loader__letter" aria-hidden="true">
								{item}
							</span>
						))}
					</p>
				</div>
			</div>
		</div>
	);
};

export default memo(AppScreenLoader);
