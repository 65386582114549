//library
import React, { memo, forwardRef, useState, useEffect } from "react";
import { Modal } from "@mui/material";

//components
import AppPopupCardDto, { ImperativeHandleAppPopupCardDto } from "dto/components/app-popup-card-dto";

const AppPopupCard = (props: AppPopupCardDto, ref: React.ForwardedRef<ImperativeHandleAppPopupCardDto>) => {
	const [isVisible, setIsVisible] = useState(false);

	const onHandleDismiss = () => {
		setIsVisible(false);
	};

	const onHandleShow = () => {
		setIsVisible(true);
	};

	React.useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;
		if (isVisible) {
			timer = setTimeout(() => {
				setIsVisible(false);
			}, 5000);
		}
		return () => clearTimeout(timer);
	}, [isVisible]);

	return (
		<Modal classes={{ root: "app-popup-card" }} open={isVisible} aria-labelledby="app-popup-card" aria-describedby="app-popup-card" onClose={onHandleDismiss}>
			<div className="popup-card">
				<img className="popup-card__icon" src={props.image} alt="icon" />

				<p className="popup-card__title">{props.title}</p>

				<p className="popup-card__title">{props.subTitle}</p>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPopupCard));
