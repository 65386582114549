const encodeQueryParams = (data: { [key: string]: any } | string) => {
	if (!data) return "";

	const query = [];

	if (typeof data === "string") return data;

	for (let q in data) {
		if (data[q] !== null && data[q] !== undefined && data[q] !== "") query.push(encodeURIComponent(q) + "=" + encodeURIComponent(data[q]));
	}

	if (query.length) {
		return "?" + query.join("&");
	}

	return "";
};

export default encodeQueryParams;
