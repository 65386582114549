import { AxiosError, isAxiosError } from "axios";

import ErrorResponseDto from "dto/services/error-response-dto";

const sanitizeError = (error: AxiosError | Error | string): string => {
	if (error === typeof "string") return error;

	if (isAxiosError(error)) {
		const { response, message } = error as ErrorResponseDto;

		if (response?.data?.exceptionMessage) return response.data.exceptionMessage;

		const isCancelled = message === "canceled";

		if (isCancelled) return "";

		return message ?? "";
	} else {
		return error.toString();
	}
};

export default sanitizeError;
