import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
	children: ReactElement;
};

const ScrollToTop = ({ children }: Props) => {
	const location = useLocation();

	useEffect(() => {
		const w = window.scrollTo(0, 0);

		return () => w;
	}, [location.pathname]);

	return children;
};

export default ScrollToTop;
