// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// hooks
import useTemplateContext from "hooks/use-template-context";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import AllArticleDto from "dto/pages/page-all-articles-dto";
import ErrorResponseDto from "dto/services/error-response-dto";

// components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPagination from "components/app-pagination";
import AppArticleCard from "components/app-article-card";
import AppCategoryTab from "components/app-category-tab";

const PageAllArticles = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [categories, setCategories] = useState<AllArticleDto[]>([]);
	const [articles, setArticles] = useState<AllArticleDto[]>([]);
	const { getFilteredResources } = useTemplateContext();

	const queryParams = useRef({ search: "", page: 0, size: 9 });

	const totalPageNumber = useMemo(() => Math.ceil(articles.length / 9), [articles]);

	const paginatedArticles = useMemo(() => articles.slice(currentPage * 9, (currentPage + 1) * 9), [articles, currentPage]);

	const { articleCoverHeader, articleCover, articleHeader } = useMemo(() => {
		const articlePage = getFilteredResources("page.article") || [];
		return {
			articleCoverHeader: articlePage.find((item) => item.key === "page.article.cover.header")?.value?.toString() || "",
			articleCover: articlePage.find((item) => item.key === "page.article.cover")?.value?.toString() || "",
			articleHeader: articlePage.find((item) => item.key === "page.article.header")?.value?.toString() || "",
		};
	}, [getFilteredResources]);

	//prettier-ignore
	const updateCurrentPage = useCallback((newPage: number) => {
		if (newPage >= 0 && newPage < totalPageNumber) {
			setCurrentPage(newPage);
		}
	}, [totalPageNumber]);

	const onHandleGetAllArticles = useCallback(async () => {
		try {
			const { page, search } = queryParams.current;

			const response = await api.get.ourArticles.allArticles({ size: 100, page, param: search });

			setArticles(response.data?.data?.list ?? []);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetSimilarArticles = useCallback(async (category: string) => {
		try {
			const response = await api.get.similarArticle.article(category);

			setArticles(response.data?.data?.list ?? []);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	//prettier-ignore
	const onHandleSelect = useCallback((tab: any) => {
		const categoryTitle = tab.title;
		setCurrentPage(0);
	
		if (categoryTitle.toLowerCase() === "all") {
			onHandleGetAllArticles();
		} else {
			onHandleGetSimilarArticles(categoryTitle);
		} 
	}, [onHandleGetAllArticles, onHandleGetSimilarArticles]);

	const onHandleGetCategoriesTitle = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 5, page: params.page };

			response = await api.get.categoryTitle.categoryTitle(payload);

			const dataContent: AllArticleDto[] = response.data.data.list.content;

			const mapDataContent = dataContent.map((m, i) => ({ ...m, indexKey: i }));

			setCategories(mapDataContent);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	useEffect(() => {
		onHandleGetCategoriesTitle();
		onHandleGetAllArticles();
	}, [onHandleGetAllArticles, onHandleGetCategoriesTitle]);

	return (
		<div className="page-all-articles">
			<div className="articles">
				<AppNavbar />

				<HeaderCard backgroundImage={articleCover} title={articleCoverHeader} />

				<div className="tabs-section">{categories.length > 0 && <AppCategoryTab tabs={categories} onSelect={onHandleSelect} />}</div>

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">{articleHeader}</p>

						{totalPageNumber > 1 && (
							<AppPagination totalPageNumber={totalPageNumber} currentPage={currentPage} onHandleNext={() => updateCurrentPage(currentPage + 1)} onHandleBack={() => updateCurrentPage(currentPage - 1)} />
						)}
					</div>

					<div className="articles-section__wrapper">
						{paginatedArticles.map((article) => (
							<AppArticleCard title={article.title} description={article.category} image={article.thumbnail} key={article.id} link={`${pathnames.PageArticle}/${article.id}`} />
						))}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageAllArticles;
